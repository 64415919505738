'use client';
import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useMemo,
  startTransition,
} from 'react';
import { Permission } from '@safe-global/safe-apps-sdk/dist/types/types/permissions';
import { useSearchParams } from 'next/navigation';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import { globalLogger } from '@/lib/logger';
import { runMutation, runQuery } from '@/features/dapp-iframe/actions';
import { PermissionStatus } from '@/features/dapp-iframe/types';
import { useRouter } from '@/lib/navigation';
import { LinkTo } from '@/lib/links';
import { useRunOnSubscription } from '@/lib/subscriptions';

import { useDappState, useDappPermissions, DappPermissionsRequest } from '../../context';

import { BridgeMessageMethod, Metadata } from './types';

const logger = globalLogger.child({ scope: 'bridge' });

/**
 * Maximum number of active subscriptions.
 * Limited to 3 per dapp to prevent exceeding the backend's maximum allowed subscriptions (100).
 */
const MAX_SUBSCRIPTIONS = 3;


/**
 * Interface for the Bridge context type.
 */
interface BridgeContextType {
  isInitialized: boolean;
  sendMessage: (method: BridgeMessageMethod, params: any) => void;
  acceptPermissionRequest: (requestId: number, capabilities: Permission[]) => void;
}

/**
 * BridgeContext for providing bridge communication.
 */
const BridgeContext = createContext<BridgeContextType | null>(null);

/**
 * Type representing an active subscription.
 */
interface ActiveSubscription {
  id: string;
  subscription: string;
  variables: Record<string, any>;
}

/**
 * SubscriptionManager component to handle individual subscriptions using useRunOnSubscription hook.
 *
 * @param {ActiveSubscription} subscription - The subscription object containing id, subscription query, and variables.
 * @param {(method: BridgeMessageMethod, params: any) => void} sendMessage - Function to send messages through the bridge.
 */
const SubscriptionManager: React.FC<{
  subscription: ActiveSubscription;
  sendMessage: (method: BridgeMessageMethod, params: any) => void;
}> = ({ subscription, sendMessage }) => {
  const graphqlSubscription = useMemo(() => gql(subscription.subscription), [subscription.subscription]);

  useRunOnSubscription(graphqlSubscription, {
    onData: (result) => {
      sendMessage(BridgeMessageMethod.SUBSCRIPTION_DATA, {
        subscriptionId: subscription.id,
        data: result.data,
      });
    },
    onError: (error) => {
      sendMessage(BridgeMessageMethod.SUBSCRIPTION_ERROR, {
        subscriptionId: subscription.id,
        error: error.message || 'Subscription error',
      });
    },
  });

  return null;
};

/**
 * BridgeProvider component to manage communication between the parent window and an iframe using MessagePort.
 *
 * This component establishes a MessagePort for sending and receiving messages, particularly for handling
 * GraphQL requests and responses. It listens for messages from the iframe and processes them accordingly.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 */
export const BridgeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading: isDappLoading, settings } = useDappState();
  const { mutations } = useDappPermissions();
  const [activeSubscriptions, setActiveSubscriptions] = useState<ActiveSubscription[]>([]);

  const [isInitialized, setIsInitialized] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();

  const isListeningRef = useRef(false);
  const portRef = useRef<MessagePort | null>(null);

  // Ref to avoid stale closures
  const settingsRef = useRef(settings);
  const searchParamsRef = useRef(searchParams);

  const methodPermissionMap = useMemo(
    () =>
      ({
        [BridgeMessageMethod.GRAPHQL_QUERY]: [{ runQuery: {} }],
        [BridgeMessageMethod.GRAPHQL_MUTATION]: [{ runAction: {} }],
        [BridgeMessageMethod.LOAD_METADATA]: [{ loadMetadata: {} }],
        [BridgeMessageMethod.SWITCH_VAULT]: [{ switchVault: {} }],
        [BridgeMessageMethod.SWITCH_NETWORK]: [{ switchNetwork: {} }],
        [BridgeMessageMethod.SUBSCRIBE]: [{ subscribe: {} }],
      }) as const,
    [],
  );

  /**
   * Update the ref when the settings change to avoid stale closures
   */
  useEffect(() => {
    settingsRef.current = settings;
  }, [settings]);

  /**
   * Update the ref when the search params change to avoid stale closures
   */
  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  /**
   * Updates the search params for the dApp
   */
  const updateSearchParam = useCallback(
    (param: string, value: string) => {
      logger.info('[Bridge] Updating search param:', param, value);
      startTransition(() => {
        router.push(
          LinkTo.dapp({
            searchParams: {
              ...Object.fromEntries(searchParamsRef.current.entries()),
              [param]: value,
            },
          }),
        );
        router.refresh();
      });
    },
    [router],
  );

  /**
   * Checks if a method is a valid BridgeMessageMethod.
   *
   * @param {string} method - The method to check.
   * @returns {boolean} - True if the method is valid, false otherwise.
   */
  const isBridgeMessageMethod = useCallback((method: string): method is BridgeMessageMethod => {
    return Object.values(BridgeMessageMethod).includes(method as BridgeMessageMethod);
  }, []);

  /**
   * Sends a message to the iframe using the established MessagePort.
   *
   * @param {BridgeMessageMethod} method - The method type of the message.
   * @param {Object} params - Additional parameters to include in the message.
   */
  const sendMessage = useCallback((method: BridgeMessageMethod, params: any) => {
    if (!portRef.current) {
      logger.error('[Bridge] No port found');
      return;
    }

    const data = { method, ...params };
    logger.info('[Bridge] sendDataToIframe', data);

    portRef.current.postMessage(data);
  }, []);

  /**
   * Handles the acceptance of a permission request.
   *
   * @param {number} requestId - The ID of the permission request.
   * @param {Permission[]} permissions - The list of permissions being granted.
   */
  const acceptPermissionRequest = useCallback(
    (requestId: number, permissions: Permission[]) => {
      mutations.confirmPermissionRequest(PermissionStatus.GRANTED);
      sendMessage(BridgeMessageMethod.PERMISSION_RESPONSE, {
        id: requestId,
        data: {
          granted: true,
          permissions,
        },
      });
      logger.info(
        `[Bridge] Permission granted for requestId: ${requestId}, permissions: ${JSON.stringify(permissions)}`,
      );
    },
    [mutations, sendMessage],
  );

  /**
   * Handles incoming GraphQL queries from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the GraphQL query and variables.
   */
  const handleGraphQLQuery = useCallback(
    async (id: number, payload: any) => {
      const { query, variables } = payload ?? {};

      const respondWithError = async (error: string) => {
        sendMessage(BridgeMessageMethod.GRAPHQL_RESPONSE, {
          id,
          data: { error },
        });
      };

      if (!query || !variables) {
        await respondWithError('Missing query or variables parameters');
        return;
      }

      try {
        const result = await runQuery(query, variables);
        logger.info('[Bridge] query result:', result);
        sendMessage(BridgeMessageMethod.GRAPHQL_RESPONSE, {
          id,
          data: result,
        });
      } catch (err) {
        logger.error('[Bridge] Error running query', err);
        await respondWithError('Error running query');
      }
    },
    [sendMessage],
  );

  /**
   * Handles incoming GraphQL mutations from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the GraphQL mutation and variables.
   */
  const handleGraphQLMutation = useCallback(
    async (id: number, payload: any) => {
      const { mutation, variables } = payload ?? {};

      const respondWithError = async (error: string) => {
        sendMessage(BridgeMessageMethod.GRAPHQL_RESPONSE, {
          id,
          data: { error },
        });
      };

      if (!mutation || !variables) {
        await respondWithError('Missing mutation or variables parameters');
        return;
      }

      try {
        const result = await runMutation(mutation, variables);
        logger.info('[Bridge] mutation result:', result);
        sendMessage(BridgeMessageMethod.GRAPHQL_RESPONSE, {
          id,
          data: result,
        });
      } catch (err) {
        logger.error('[Bridge] Error running mutation', err);
        await respondWithError('Error running mutation');
      }
    },
    [sendMessage],
  );

  /**
   * Handles Load Metadata requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   */
  const handleLoadMetadata = useCallback(
    async (id: number) => {
      const currentSettings = settingsRef.current;
      const metadata: Metadata = {
        vaultId: currentSettings.vaultId,
        network: currentSettings.currentChain.name,
        userId: currentSettings.user?.id,
        organizationId: currentSettings.user?.org_id,
        environment: process.env.NODE_ENV,
      };

      sendMessage(BridgeMessageMethod.LOAD_METADATA_RESPONSE, {
        id,
        data: { metadata },
      });
      logger.info('[Bridge] Metadata loaded and sent to iframe:', metadata);
    },
    [sendMessage],
  );

  /**
   * Handles incoming switch vault requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the vault ID to switch to.
   */
  const handleSwitchVault = useCallback(
    async (id: number, payload: { vaultId: string }) => {
      try {
        logger.info('[Bridge] Switching vault to:', payload.vaultId);
        updateSearchParam('vaultId', payload.vaultId);
        sendMessage(BridgeMessageMethod.SWITCH_VAULT_RESPONSE, {
          id,
          data: { success: true },
        });
      } catch (err) {
        logger.error('[Bridge] Error switching vault:', err);
        sendMessage(BridgeMessageMethod.SWITCH_VAULT_RESPONSE, {
          id,
          data: { success: false, error: 'Failed to switch vault' },
        });
      }
    },
    [sendMessage, updateSearchParam],
  );

  /**
   * Handles incoming switch network requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the network to switch to.
   */
  const handleSwitchNetwork = useCallback(
    async (id: number, payload: { network: string }) => {
      try {
        logger.info('[Bridge] Switching network to:', payload.network);
        updateSearchParam('network', payload.network);
        sendMessage(BridgeMessageMethod.SWITCH_NETWORK_RESPONSE, {
          id,
          data: { success: true },
        });
      } catch (err) {
        logger.error('[Bridge] Error switching network:', err);
        sendMessage(BridgeMessageMethod.SWITCH_NETWORK_RESPONSE, {
          id,
          data: { success: false, error: 'Failed to switch network' },
        });
      }
    },
    [sendMessage, updateSearchParam],
  );

  /**
   * Handles incoming subscribe requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the subscription and variables.
   */
  const handleSubscribe = useCallback(
    async (id: number, payload: { subscription: string; variables: Record<string, any> }) => {
      try {
        logger.info('[Bridge] Subscribing to:', payload.subscription);
        const subscriptionId = id.toString();

        // Check if we've reached the subscription limit
        if (activeSubscriptions.length >= MAX_SUBSCRIPTIONS) {
          logger.warn(`[Bridge] Subscription limit reached (max: ${MAX_SUBSCRIPTIONS})`);
          sendMessage(BridgeMessageMethod.SUBSCRIBE_RESPONSE, {
            id,
            data: {
              success: false,
              error: `Maximum number of active subscriptions (${MAX_SUBSCRIPTIONS}) reached`,
            },
          });
          return;
        }

        // Add new subscription to state
        setActiveSubscriptions((prev) => [
          ...prev,
          {
            id: subscriptionId,
            subscription: payload.subscription,
            variables: payload.variables,
          },
        ]);

        // Send initial success response
        sendMessage(BridgeMessageMethod.SUBSCRIBE_RESPONSE, {
          id,
          data: {
            success: true,
            subscriptionId: subscriptionId,
          },
        });
      } catch (err) {
        logger.error('[Bridge] Error subscribing:', err);
        sendMessage(BridgeMessageMethod.SUBSCRIBE_RESPONSE, {
          id,
          data: { success: false, error: 'Failed to subscribe' },
        });
      }
    },
    [sendMessage, activeSubscriptions],
  );

  /**
   * Handles incoming unsubscribe requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {Object} payload - The payload containing the subscriptionId to unsubscribe from.
   */
  const handleUnsubscribe = useCallback(
    async (id: number, payload: { subscriptionId: string }) => {
      const { subscriptionId } = payload;
      logger.info('[Bridge] Unsubscribing from:', subscriptionId);

      setActiveSubscriptions((prev) => prev.filter((sub) => sub.id !== subscriptionId));

      sendMessage(BridgeMessageMethod.UNSUBSCRIBE_RESPONSE, {
        id,
        data: { success: true },
      });
    },
    [sendMessage],
  );

  /**
   * Handles permission requests from the iframe.
   *
   * @param {number} id - The request ID for tracking the request.
   * @param {string[]} capabilities - The list of capabilities being requested.
   */
  const handlePermissionRequest = useCallback(
    async (id: number, capabilities: string[]) => {
      mutations.setPermissionsRequest({
        origin: window.origin,
        requestId: id.toString(),
        request: capabilities.map((cap) => ({ [cap]: {} })),
      });
    },
    [mutations],
  );

  /**
   * Handles incoming messages from the iframe.
   *
   * @param {MessageEvent} event - The message event containing the data.
   */
  const handleMessageEvent = useCallback(
    async (event: MessageEvent) => {
      const data = event.data;

      logger.info('[Bridge] handleEvent for method', data.method);

      if (!isBridgeMessageMethod(data.method)) {
        logger.error(`[Bridge] Invalid message method: ${data.method}`);
        return;
      }

      const permissionRequests = methodPermissionMap[data.method as keyof typeof methodPermissionMap];

      if (permissionRequests) {
        const permissionRequest: DappPermissionsRequest = {
          origin: window.origin,
          requestId: data.id.toString(),
          request: [...permissionRequests],
        };

        if (!mutations.checkPermissions(permissionRequest)) {
          sendMessage(data.method, {
            id: data.id,
            error: 'Permission denied',
          });
          logger.warn(`[Bridge] Permission denied for method: ${data.method}`);
          return;
        }
      }

      try {
        switch (data.method) {
          case BridgeMessageMethod.GRAPHQL_QUERY: {
            await handleGraphQLQuery(data.id, data.payload);
            break;
          }
          case BridgeMessageMethod.GRAPHQL_MUTATION: {
            await handleGraphQLMutation(data.id, data.payload);
            break;
          }
          case BridgeMessageMethod.LOAD_METADATA: {
            await handleLoadMetadata(data.id);
            break;
          }
          case BridgeMessageMethod.REQUEST_PERMISSION: {
            await handlePermissionRequest(data.id, data.payload.capabilities);
            break;
          }
          case BridgeMessageMethod.SWITCH_VAULT: {
            await handleSwitchVault(data.id, data.payload);
            break;
          }
          case BridgeMessageMethod.SWITCH_NETWORK: {
            await handleSwitchNetwork(data.id, data.payload);
            break;
          }
          case BridgeMessageMethod.SUBSCRIBE: {
            await handleSubscribe(data.id, data.payload);
            break;
          }
          case BridgeMessageMethod.UNSUBSCRIBE: {
            await handleUnsubscribe(data.id, data.payload);
            break;
          }
          default: {
            throw new Error(`Unhandled bridge message method: ${data.method}`);
          }
        }
      } catch (err) {
        logger.error('[Bridge] Error handling message:', err);
        sendMessage(data.method, {
          id: data.id,
          error: 'Error handling message',
        });
      }
    },
    [
      isBridgeMessageMethod,
      methodPermissionMap,
      mutations,
      sendMessage,
      handleGraphQLQuery,
      handleGraphQLMutation,
      handleLoadMetadata,
      handlePermissionRequest,
      handleSwitchVault,
      handleSwitchNetwork,
      handleSubscribe,
      handleUnsubscribe,
    ],
  );

  /**
   * Handles message event errors.
   *
   * @param {MessageEvent} event - The message event containing the error.
   */
  const handleMessageEventError = useCallback((event: MessageEvent) => {
    console.error('[Bridge] MessageEvent error:', event);
  }, []);

  /**
   * Handles the acceptance of a permission request.
   *
   * @param {boolean} granted - Whether the permission was granted.
   * @param {number} requestId - The ID of the permission request.
   */
  const handlePermissionResponse = useCallback(
    (granted: boolean, requestId: number) => {
      mutations.confirmPermissionRequest(granted ? PermissionStatus.GRANTED : PermissionStatus.DENIED);
      sendMessage(BridgeMessageMethod.PERMISSION_RESPONSE, { id: requestId, granted });
      logger.info(`[Bridge] Permission response sent for requestId: ${requestId}, granted: ${granted}`);
    },
    [mutations, sendMessage],
  );

  /**
   * Listener for permission responses from the user.
   *
   * @param {MessageEvent} event - The message event containing the permission response.
   */
  const permissionResponseListener = useCallback(
    (event: MessageEvent) => {
      if (event.data.method === BridgeMessageMethod.PERMISSION_RESPONSE) {
        const { granted, id } = event.data;
        logger.info(`[Bridge] Received PERMISSION_RESPONSE: granted=${granted}, id=${id}`);
        handlePermissionResponse(granted, id);
      }
    },
    [handlePermissionResponse],
  );

  /**
   * Listens for the establishment of the MessagePort from the iframe.
   *
   * @param {MessageEvent} event - The message event containing the port establishment data.
   */
  const portEstablishmentListener = useCallback(
    (event: MessageEvent) => {
      if (event.data?.method !== BridgeMessageMethod.PORT_ESTABLISHED || !event?.ports?.length) return;

      const port = event.ports[0];

      portRef.current = port;

      portRef.current.addEventListener('message', handleMessageEvent);
      portRef.current.addEventListener('messageerror', handleMessageEventError);

      portRef.current.start();

      // Send confirmation to the iframe
      sendMessage(BridgeMessageMethod.PORT_ESTABLISHED, {
        data: {
          status: 'success',
        },
      });

      logger.info('[Bridge] Port established');

      setIsInitialized(true);
    },
    [handleMessageEvent, handleMessageEventError, sendMessage],
  );

  useEffect(() => {
    if (isListeningRef.current || isDappLoading) return;
    isListeningRef.current = true;

    logger.info('[Bridge] Bridge initialization....');
    window.addEventListener('message', portEstablishmentListener);
    window.addEventListener('message', permissionResponseListener);
  }, [portEstablishmentListener, permissionResponseListener, isDappLoading]);

  const value = {
    isInitialized,
    sendMessage,
    acceptPermissionRequest,
  };

  return (
    <BridgeContext.Provider value={value}>
      {children}
      {activeSubscriptions.map((sub) => (
        <SubscriptionManager key={sub.id} subscription={sub} sendMessage={sendMessage} />
      ))}
    </BridgeContext.Provider>
  );
};

/**
 * Custom hook to use the Bridge context.
 *
 * @returns {BridgeContextType} The Bridge context value.
 * @throws {Error} If used outside of a BridgeProvider.
 */
export function useBridge() {
  const context = useContext(BridgeContext);
  if (!context) {
    throw new Error('useBridge must be used within a BridgeProvider');
  }
  return context;
}
